import Swal from 'sweetalert2'

const SwalDelete = Swal.mixin({
  title: 'Tem certeza?',
  text: 'Você tem certeza que deseja excluir?',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Excluir',
  cancelButtonText: 'Cancelar'
})

export default SwalDelete
