<template>
  <layout page="banner-list">
    <page-title icon="lni lni-gallery" :breadcrumb="breadcrumb">Banners</page-title>
    <div
      class="drop-area"
      :class="{'dragging': dragging}"
      @dragenter.prevent="dragEnterHandler"
      @dragover.prevent="dragEnterHandler"
      @dragleave.prevent="dropHandler"
      @drop.prevent="dropHandler"
      @click="() => { $refs.src.click() }"
    >
      <div v-if="banners.length === 0" class="no-files">
        <div class="upload-call">
          <i class="upload-icon lni lni-cloud-upload" />
          <div class="upload-text">
            <span>Arraste seus arquivos para cá</span>
            <div class="divider">Ou</div>
            <span>Clique aqui</span>
          </div>
        </div>
      </div>
      <input style="display: none" type="file" @change="inputHandler" name="src" id="src" ref="src" />
    </div>
    <div
      class="banners"
      @dragenter.prevent="dragEnterHandler"
      @dragover.prevent="dragEnterHandler"
      @dragleave.prevent="dropHandler"
      @drop.prevent="dropHandler"
      @click="() => { $refs.src.click() }"
    >
      <div class="columns is-multiline">
        <div
          v-for="(banner, index) in banners"
          class="column is-one-third"
          :key="banner.id"
        >
          <div class="card card-banner">
            <div class="card-image">
              <figure class="image is-4by3">
                <img :src="`${API_URL}/public/banners/${banner.src}`" :alt="banner.alt">
              </figure>
            </div>
            <div
              class="card-delete"
              @click.stop="remove(banner, index)"
            >
              <i class="lni lni-trash has-text-light" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import { api, API_URL } from '@/api'
import SwalDelete from '@/helpers/SwalDelete'
import { DASHBOARD, BANNERS } from '@/config/breadcrumb/admin'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'

export default {
  name: 'BannerList',
  components: {
    Layout,
    PageTitle
  },
  created () {
    api.get('/banners').then(res => {
      this.banners = res.data
    })
  },
  data () {
    return {
      banners: [],
      dragging: false,
      src: ''
    }
  },
  computed: {
    breadcrumb () {
      return [
        DASHBOARD,
        { ...BANNERS, isActive: true }
      ]
    },
    API_URL () {
      return API_URL
    }
  },
  methods: {
    dragEnterHandler () {
      this.dragging = true
    },
    dropHandler (e) {
      this.dragging = false
      const dt = e.dataTransfer
      const files = dt.files
      this.upload(files[0])
    },
    upload (file) {
      const types = ['image/jpeg', 'image/png', 'image/jpg']
      if (file === undefined) return
      if (!types.includes(file.type)) return

      const formData = new FormData()
      formData.append('src', file)

      api.post('/banners', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.status === 201) {
          this.banners.push(res.data)
        } else {
          alert('upload não concluido')
        }
      }).catch(() => {
        alert('servidor não responde')
      })
    },
    inputHandler () {
      this.upload(this.$refs.src.files[0])
    },
    remove (banner, index) {
      SwalDelete.fire().then(result => {
        if (result.isConfirmed) {
          api.delete(`/banners/${banner.id}`).then(res => {
            if (res.status === 200) {
              this.banners.splice(index, 1)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.banner-list {
  .drop-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    // z-index: 999;

    &.dragging {
      opacity: .5;
    }
  }
  .no-files {
    height: 100%;
  }
  .upload-call {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: -1;
    .upload-icon {
      font-size: 3rem;
      color: $primary-color;
      margin-bottom: .5rem;
    }
    .upload-text {
      text-align: center;
      font-weight: 700;
      text-transform: uppercase;
      .divider {
        margin: .3rem 0;
      }
    }
  }
  .banners {
    .card-banner {
      &:hover {
        .card-delete {
          display: flex;
        }
      }
    }
    .card-delete {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      width: 100%;
      height: 100%;
      background: rgba(#f14668, .7);
      cursor: pointer;
    }
  }
}
</style>
