const DASHBOARD = {
  path: '/admin',
  text: 'Dashboard'
}

const TEACHER_LIST = {
  path: '/admin/professores',
  text: 'Professores'
}

const TEACHER_FORM = {
  path: '/admin/professores/novo',
  text: 'Novo Professor'
}

const STUDENT_LIST = {
  path: '/admin/alunos',
  text: 'Alunos'
}

const STUDENT_FORM = {
  path: '/admin/alunos/novo',
  text: 'Novo Aluno'
}

const RESPONSIBLE_FORM = {
  path: '/admin/alunos',
  text: 'Novo Responsável'
}

const DISCIPLINE_LIST = {
  path: '/admin/disciplinas',
  text: 'Disciplinas'
}

const DISCIPLINE_FORM = {
  path: '/admin/disciplinas/nova',
  text: 'Nova Disciplina'
}

const SERIE_LIST = {
  path: '/admin/series',
  text: 'Séries'
}

const SERIE_FORM = {
  path: '/admin/series/nova',
  text: 'Nova Série'
}

const CLASS_LIST = {
  path: '/admin/turmas',
  text: 'Classes'
}

const CLASS_FORM = {
  path: '/admin/turmas/nova',
  text: 'Nova Classe'
}

const ACTIVITY_FORM = {
  path: '/admin/turmas/:idClass/editar/atividades/nova',
  text: 'Nova Atividade'
}

const BANNERS = {
  path: '/admin/banners',
  text: 'Banners'
}

const NEWS_LIST = {
  path: '/admin/noticias',
  text: 'Noticias'
}

const NEWS_FORM = {
  path: '/admin/noticias/nova',
  text: 'Nova Noticia'
}

export {
  DASHBOARD,
  TEACHER_LIST,
  TEACHER_FORM,
  STUDENT_LIST,
  STUDENT_FORM,
  RESPONSIBLE_FORM,
  DISCIPLINE_LIST,
  DISCIPLINE_FORM,
  SERIE_LIST,
  SERIE_FORM,
  CLASS_LIST,
  CLASS_FORM,
  ACTIVITY_FORM,
  BANNERS,
  NEWS_LIST,
  NEWS_FORM
}
